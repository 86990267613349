<template>
    <div class="bill">
        <div class="title">
            <span>账单记录</span>
            <div class="time" >
                <span class="date" @click="showDate=true">{{date}}</span>
                <span class="triangle"></span>
            </div>
        </div>
        <van-sticky>
            <van-tabs class="my-tabs"  sticky @change="changeTab" v-model="active" >
                <van-tab title="全部"></van-tab>
                <van-tab title="充值"></van-tab>
                <van-tab title="退款"></van-tab> 
                <van-tab title="提现"></van-tab>
                <van-tab title="消费"></van-tab>
            </van-tabs>
        </van-sticky>

        <!-- 这个不变 变得数据 -->
        <div class="tab-list">
            <van-pull-refresh class="my-pull-refresh"  success-text="刷新成功" v-model="isLoading" @refresh="onRefresh">
                <van-list
                    v-model="loading"
                    :finished="finished"
                    @load="onLoad1"
                    class="my-list"
                    >
                    <div class="my-bill-list">
                        <div class="item" v-for="item in list" :key="item.id">
                            <div class="pic">
                                <van-image class="money-image" fit="cover" :src="imgMoney" alt=""/>
                                <div class="date-type">
                                    <div class="type">
                                        <span>{{srcType[item.srcType]}}</span><span>({{billType[item.billType]}})</span>
                                    </div> 
                                    <div class="date">
                                    {{item.billDate}}
                                    </div>
                                </div>
                            </div>
                            
                            <div class="money">
                                ￥{{item.amount}}
                            </div>
                        </div>
                    </div>
                    <no-more v-show="showNoMore && !loading"/>
                </van-list>
                <no-data v-show="showData"/>
            </van-pull-refresh>
        </div>

    <van-popup v-model="showDate" position="bottom" round>
        <van-datetime-picker v-model="currentDate" type="year-month"  title="选择年月" @confirm="getDate"
            @cancel="showDate = false"
            :min-date="minDate"
            :max-date="maxDate"
        />
    </van-popup>



    </div>
</template>

<script>
import mixinPage from '@/utils/mixinPage'
export default {
    mixins: [ mixinPage ],
    data() {
        return {
            api: 'member/bill/memberAmount/memberAmount-list',
            imgMoney: require('@/assets/img/money.png'),
            active: 0,
            date:'',
            showDate: false,
            minDate: null,
            maxDate: null,
            currentDate: '',
            model: {},
            srcType: ['卡.劵订单', '商城订单', '课程订单', '充值订单'],
            billType: ['充值', '退款', '提现', '消费余额', '消费现金'],
        }
    },
    methods:{
        init() {
            this.initList()
        },
        changeTab() {
            if(this.active === 0){
                delete this.model.billType
            }else if(this.active === 1) {
                this.$set(this.model, 'billType', 0)
            }else if(this.active === 2) {
                this.$set(this.model, 'billType', 1)
            }else if(this.active === 3) {
                this.$set(this.model, 'billType', 2)
            }else{
                delete this.model.billType
                this.$set(this.model, 'billTypeStart', 3)
                this.$set(this.model, 'billTypeEnd', 4)
            }
            this.init()
        },
        getDate(value) {
            this.date = new Date(value).FormatShort().split('-')[0] + '-' + new Date(value).FormatShort().split('-')[1]
            let year = new Date(value).getFullYear()
            let month = new Date(value).getMonth()
            let days = new Date(year, month + 1, -1).getDate() + 1;
            let billDateStart = new Date(year, month, 1)
            let billDateEnd = new Date(year, month, days)
            this.$set(this.model, 'billDateStart', new Date(billDateStart).FormatShort())
            this.$set(this.model, 'billDateEnd', new Date(billDateEnd).FormatShort())
            this.init()
            this.showDate = false
        }
    },
    mounted() {
        this.init()
        this.currentDate = new Date()
        let year = new Date().getFullYear()
        let month = new Date().getMonth()
        let day = new Date().getDate()
        this.minDate = new Date(year-3, 0, 0)
        this.date = new Date().FormatShort().split('-')[0] + '-' +new Date().FormatShort().split('-')[1]
        this.maxDate = new Date(year, month, day)
    }
}   
</script>

<style lang="scss">
    @import "~@/assets/css/var";
    .bill{
        margin-top: 30px;
        .title{
            color: $main;
            font-size: 24px;
            padding: 0 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            //font-weight: bold;
            .time {
                display:flex;
                align-items: center;
                position: relative;
                padding-left: 20px;
                .date{
                    background: #fff;
                    color: $main;
                    padding: 5px 20px 5px 10px;
                    border-radius: 28px;
                }
                .triangle{
                    border:5px solid transparent;
                    border-top:5px solid $main;
                    position: absolute;
                    top:45%;
                    left: 86px;
                }
            }
            
        }
        .my-tabs{
            .van-tabs__nav{
                background-color: $backgroundColor;
                .van-tabs__line{
                    display: none;
                }
                .van-tab{
                    color: $main;
                    opacity: .3;
                }
                .van-tab--active{
                    color: $main;
                    opacity: 1!important;
                }
            }
        }
        .my-pull-refresh{
            padding: 20px 20px 0;
            .my-bill-list{
                .item{
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    margin-bottom: 20px;
                    background-color: #fff;
                    padding:10px 20px;
                    border-radius: 9px;
                    box-shadow: 0 0 9px 6px $boxShadow;
                    .money-image{
                        width: 50px;
                        height: 50px;
                    }
                    .pic{
                        display: flex;
                        align-items: center;
                    }
                    .date-type{
                        .type{
                            font-size: 14px;
                        }
                        .date{
                            color: $gray;
                        }
                    }
                    .money{
                        font-size: 14px;
                        font-weight: bold;
                        color: $main;
                    }
                }
            }
        }
    }

</style>